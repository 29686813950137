//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Loading, Dialog, date, Cookies, Platform } from 'quasar'
// import nexplayer from "@/mixins/nexplayer";
// import nexplayerComp from '@/components/nexplayer.vue'
import radiantPlayer from '@/components/radiantPlayer.vue'
import radiantPlayerAndroid from '@/components/radiantPlayerAndroid.vue'
import getTheme from "@/mixins/getTheme";
export default {
  data() {
    return {
      loaded: true,
      url: '',
      title: '',
      type: '',
      type_content: '',
      drm: '',
      data_player: '',
      contentDetails: null,
      currentState: 'state_idle',
      videoState: 'notloaded',
      adsStatus: null,
      rateSpeedPlayer: 1,
      statusPlayer: {
        paused: null
      },
      showUiController: 2,
      nexConvivaHand: null,
      nexConvivaHandLiveProgram: null,
      videoElement: null,
      dataIslive: null
    }
  },
  components: {
    // nexplayerComp,
    radiantPlayer,
    radiantPlayerAndroid
  },
  mounted(){
    this.getCatchup(date.formatDate(new Date(), "YYYY-MM-DD"))
    this.initContent(this.$route.params.id)
    // if (this.check_browser == 'safari') {
    //   document.querySelector('.plyr-media').classList.add('option-safari');
    // } else {
    //   document.querySelector('.plyr-media').classList.remove('option-safari');
    // }
    
    const vm = this
    window.cueId = this.cueId
    window.play = this.play
    window.seekTo = this.seekTo
    window.pause = this.pause
    window.getDuration = this.getDuration
    window.getCurrentPosition = this.getCurrentPosition
    window.getCurrentState = this.getCurrentState
    window.getSupportedResolutions = this.getSupportedResolutions
    window.getCurrentResolution = this.getCurrentResolution
    window.setResolution = this.setResolution
    window.getSupportedPlaybackRateList = this.getSupportedPlaybackRateList
    window.getCurrentPlaybackRate = this.getCurrentPlaybackRate
    window.setPlaybackRate = this.setPlaybackRate
    window.hidePlayerControl = this.hidePlayerControl
    window.showPlayerControl = this.showPlayerControl
    window.unMute = this.unMute

  },
  mixins: [getTheme],
  beforeRouteLeave(to, from, next) {
    if (this.nexConvivaHand) {
        this.nexConvivaHand.cleanupContentSession()   
    }
    if (this.nexConvivaHandLiveProgram) {
        this.nexConvivaHandLiveProgram.cleanupContentSession()   
    }
    // nexplayer.UnMount(document.getElementById("player"));
    next();
  },
  computed:{
    is_mobile(){
        return this.$store.getters.getPlatform == 'mobile' ? true : false;
    },
    check_browser(){
        return this.$store.getters.getBrowser
    },
    is_ios(){
        return this.$store.getters.getIosDevice
    }
  },
  methods: {
    getCatchup(date){
        // this.loaded = false
        this.isLoadingCatchUp = true
        this.catchupData = []
        let id = this.$route.params.id;
        this.$apiGet('/api/v10/epg?channel_ids='+ id +'&start_time_from='+ date)
        .then(success=>{
            if(success.data.status.code == 0){
                let dataCatchUp = success.data.data[0].schedules[0].items
                this.dataIslive = dataCatchUp.find(o => o.is_live == true) 
            }else{
              this.dataIslive = null
            }
            // this.loaded = true
        })
        .catch(error=>{})
    },
    initContent(id) {
      this.loaded = true;
      this.$apiGet("/api/v5/contents/" + id)
        .then(success => {
          if (success.data.data != null) {
            this.contentDetails = success.data.data;
            // let dataTag = success.data.data.tag
            // let type = this.contentDetails.type
            // let catId = this.contentDetails.category_id
            // let subCatId = this.contentDetails.subcategory_id
            // let uniqCode = 0
            // if (type == 'Movies') {
            //   uniqCode = '2'
            // } else if (type == 'series') {
            //   uniqCode = '1'
            // } else if (type == 'tvod') {
            //   uniqCode = '3'
            // }
            // this.contentDetails.vast = process.env.VAST_VOD + '&cust_params='+ uniqCode + catId + '%3D' + subCatId
            this.contentDetails.vast = process.env.VAST_CHANNEL
            
            this.title = success.data.data.title;
            this.initNotChannel(this.$route.params.id)
          }
        })
        .catch(error => {
          console.log(error)
          if (navigator.onLine) {
            Dialog.create({
                message: 'Something Error',
                dark: true,
                persistent: true,
            })
          }
        });
    },
    initNotChannel(id) {
      this.loaded = true;
      this.$apiGet("/api/v/channels/" + id)
        .then(success => {
          if (success.data.status.code != 11) {
            Dialog.create({
              title: "",
              message: success.data.status.message_client,
              dark: true,
              persistent: true
            });
            // nexplayer.UnMount(document.getElementById("player"));
          } else {
            this.loaded = false;
            this.data = success.data.data;
            this.data_player = this.data.player;
            this.type_content = "live";
            this.type = "live";
            this.drm = this.data_player.drm;
            if (this.drm) {
              if (Platform.userAgent.indexOf('iphone') !== -1) {
                this.url = this.data_player.hls
              } else if (Platform.userAgent.indexOf('macintosh') !== -1 && this.check_browser == 'safari') {
                this.url = this.data_player.hls
              } else {
                this.url = this.data_player.mpd
              }
            } else {
              this.url = this.data_player.hls;
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (navigator.onLine) {
            Dialog.create({
                message: 'Something Error',
                dark: true,
                persistent: true,
            })
          }
        });
    },
    getPlayerInstance(_instance) {
      this.instance = _instance
    },
    cueId(id, statTime) {
      if (id == this.$route.params.id || id == this.$route.params.ccid) {
        this.seekTo(statTime) 
      }
    },
    play() {
      this.instance.togglePlayPause()
    },
    seekTo(time) {
      this.instance.seek(time)
    },
    pause() {
      this.instance.togglePlayPause()
    },
    getDuration() {
      return this.instance.getDuration()
    },
    getTest() {
      return 'test get'
    },
    getCurrentPosition() {
      return this.instance.getCurrentTime()
    },
    getCurrentState() {
      return this.currentState
    },
    setCurrentState(state) {
      this.statusPlayer = document.getElementById("nexplayer_videoplayer");
      this.currentState = state
    },
    fullscreen() {
      this.instance.toggleFullScreen()
    },
    getSupportedResolutions() {
      let reselotion = this.instance.getTracks()
      let allResolution = []
      reselotion.forEach(element => {
        allResolution.push(element.height)
      });
      return allResolution.toString()
    },
    getCurrentResolution() {
      let reselotion = this.instance.getCurrentTrack()
      return reselotion.height
    },
    setResolution(param) {
      let reselotion = this.instance.getTracks()
      let data = {}
      reselotion.forEach(element => {
        if (element.height == param) {
          data = element
        }
      });
      this.instance.setCurrentTrack(data.id)
      //send message to native
    },
    getSupportedPlaybackRateList() {
      let rateSpeed = '0.5,1,1.5,2'
      return rateSpeed
    },
    getCurrentPlaybackRate() {
      let rateSpeed = this.rateSpeedPlayer
      return rateSpeed
    },
    setPlaybackRate(param) {
      this.rateSpeedPlayer = param
      this.instance.setSpeed(param)
      //send message to native
    },
    hidePlayerControl() {
      this.showUiController = 1
      let plyr = document.getElementsByClassName('plyr-media')[0]
      plyr.classList.add('hide-controll');
    },
    showPlayerControl() {
      this.showUiController = 2
      let plyr = document.getElementsByClassName('plyr-media')[0]
      plyr.classList.remove('hide-controll');
    },
    videoPlaying(_state) {
      this.videoState = _state;
      console.log('this.videoState', this.videoState);
      clearTimeout(this.timeoutEvent);
      // console.log('cekcar ==>', document.querySelector(".top_bar_box"));
      // if (document.querySelector(".top_bar_box") == null) {
      //   const parentEl = document.querySelector(".nexplayer_video")
      //   const play = `<div class="top_bar_box" :class="{'safari-mobile': is_ios && check_browser == 'safari'}">
      //       <img v-if="videoState == 'paused' || videoState == 'notloaded'" @click="play" src="/statics/icons/play_button.png" height="30px" alt="">
      //       <img v-else @click="play" src="/statics/icons/pause_button.png" height="30px" alt="">
      //   </div>`
      //   console.log('parentEl ==>', parentEl); 
      //   parentEl.innerHTML += play 
      // }
      document.querySelector('.plyr-media').classList.add('tv_live');

      const textTest = document.querySelector('.nexplayer_text_div');
      if (textTest) {
        textTest.innerHTML = `<div class="q-pl-sm blink_me" style="display: inline; color: #ff0000; font-size: 13px; font-weight: bold"><i aria-hidden="true" class="material-icons q-icon">lens</i><span style="margin-left: 4px; font-size: 12px;vertical-align: unset;">Live</span></div>`
      }
      var myVideo = document.getElementById("nexplayer_videoplayer");
      if (myVideo.paused) {
          if (document.getElementsByClassName('top_bar_box')[0]) {
              if (!document.getElementsByClassName('top_bar_box')[0].classList.contains('activated')) {
                  document.getElementsByClassName('top_bar_box')[0].classList.add('activated');
              }
          }
      } else {
          if (document.getElementsByClassName('top_bar_box')[0]) {
              if (!document.getElementsByClassName('top_bar_box')[0].classList.contains('activated')) {
                  document.getElementsByClassName('top_bar_box')[0].classList.add('activated');
              }
          }
          this.timeoutEvent = setTimeout(function() {
              if (document.getElementsByClassName('top_bar_box')[0]) {
                  const titleObj = document.getElementsByClassName('top_bar_box')[0];
                  if (titleObj.classList.contains('activated')) {
                      titleObj.classList.remove('activated');
                  }
              }
          }, 1500);
      }
      // if (_state == 'playing') {
      //     if (document.getElementsByClassName('top_bar_box')[0]) {
      //         if (!document.getElementsByClassName('top_bar_box')[0].classList.contains('activated')) {
      //             document.getElementsByClassName('top_bar_box')[0].classList.add('activated');
      //         }
      //     }
      //     this.timeoutEvent = setTimeout(function() {
      //         if (document.getElementsByClassName('top_bar_box')[0]) {
      //             const titleObj = document.getElementsByClassName('top_bar_box')[0];
      //             if (titleObj.classList.contains('activated')) {
      //                 titleObj.classList.remove('activated');
      //             }
      //         }
      //     }, 1500);
      // } else if (_state == 'paused') {
      //     if (document.getElementsByClassName('top_bar_box')[0]) {
      //         if (!document.getElementsByClassName('top_bar_box')[0].classList.contains('activated')) {
      //             document.getElementsByClassName('top_bar_box')[0].classList.add('activated');
      //         }
      //     }
      // } else {
      //   if (document.getElementsByClassName('top_bar_box')[0]) {
      //       const titleObj = document.getElementsByClassName('top_bar_box')[0];
      //       if (titleObj.classList.contains('activated')) {
      //           titleObj.classList.remove('activated');
      //       }
      //   }
      // }
    },
    showTitleState() {
      var myVideo = document.getElementById("nexplayer_videoplayer");
      if (!myVideo.paused) {
        clearTimeout(this.timeoutEvent);
        if (document.getElementsByClassName('top_bar_box')[0]) {
            if (!document.getElementsByClassName('top_bar_box')[0].classList.contains('activated')) {
                document.getElementsByClassName('top_bar_box')[0].classList.add('activated');
            }
        }

        this.timeoutEvent = setTimeout(function() {
            if (document.getElementsByClassName('top_bar_box')[0]) {
                const titleObj = document.getElementsByClassName('top_bar_box')[0];
                if (titleObj.classList.contains('activated')) {
                    titleObj.classList.remove('activated');
                }
            }
        }, 1500); 
      }
    },
    setAdsStatus(param) {
      let vm = this
      this.adsStatus = param
      console.log('param ===>', param);
      if (param == 'ads_end') {
        setTimeout(function() {
          var myVideo = document.getElementById("nexplayer_videoplayer");
          if (myVideo.paused) {
            this.videoState = 'paused'
            if (document.getElementsByClassName('top_bar_box')[0]) {
              if (!document.getElementsByClassName('top_bar_box')[0].classList.contains('activated')) {
                  document.getElementsByClassName('top_bar_box')[0].classList.add('activated');
              }
            }
          }
        }, 1000);
      }
    },
    setHandshake(param) {
        this.nexConvivaHand = param
    },
    setHandshakeLiveProgram(param) {
      this.nexConvivaHandLiveProgram = param
    },
    setElement(param) {
      this.videoElement = param
    },
    unMute() {
      // if (this.videoElement.muted) {
      //   if (document.getElementsByClassName('nexplayer_volume')[0]) {
      //     document.getElementsByClassName('nexplayer_volume')[0].click();
      //   }
      // }
    }
  }
};
